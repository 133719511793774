<template>
  <div class="modal fade" id="advancedReportModal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Gelişmiş Raporlama</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault1"
              value="added"
              v-model="type"
            />
            <label class="form-check-label" for="flexRadioDefault1">
              Genel Rapor
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault2"
              value="not_added"
              v-model="type"
            />
            <label class="form-check-label" for="flexRadioDefault2">
              Indeks'e Eklenmeyen Kişileri Getir
            </label>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
            Kapat
          </button>
          <button type="button" @click="getReport()" class="btn btn-success">
            Rapor'u indir
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min.js";
import axios from "axios";

export default {
  name: "AdvancedReportPopup",
  data() {
    return {
      type: "added",
    };
  },
  mounted() {
    this.$store.commit(
      "setAdvancedReportPopup",
      new bootstrap.Modal("#advancedReportModal")
    );
  },
  methods: {
    getReport() {
      axios
        .post(
          `/participants/get_report`,
          {
            type: this.type,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters._token}`,
            },
            responseType: "arraybuffer",
          }
        )
        .then((response) => {
          let blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.setAttribute("download", "Rapor");
          a.click();
          this.$store.getters._advanced_report_popup.hide();
          this.$swal({
            icon: "success",
            text: "Rapor Başarıyla oluşturuldu",
          });
        })
        .catch((res) => {
          this.$swal({
            icon: "error",
            text: res.response.data.message,
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped></style>
