<template>
  <div
    v-if="_total_participant_page != 1"
    class="d-flex justify-content-center align-items-center gap-2 mb-5"
  >
    <p class="mb-0">
      {{ _participant_current_page }}/{{ _total_participant_page }}
    </p>
    <button
      class="btn btn-light btn-sm"
      @click="setPage(1)"
      :disabled="_participant_current_page == 1"
    >
      <i class="bi bi-chevron-double-left"></i>
    </button>
    <button
      class="btn btn-light btn-sm"
      @click="setPage(_participant_current_page - 1)"
      :disabled="_participant_current_page - 1 < 1"
    >
      <i class="bi bi-chevron-left"></i>
    </button>
    <button
      class="btn btn-primary btn-sm"
      @click="setPage(page)"
      v-for="page in rangePage"
      :key="page"
      :disabled="_participant_current_page == page"
    >
      {{ page }}
    </button>
    <button
      class="btn btn-light btn-sm"
      @click="setPage(_participant_current_page + 1)"
      :disabled="_participant_current_page + 1 > _total_participant_page"
    >
      <i class="bi bi-chevron-right"></i>
    </button>
    <button
      class="btn btn-light btn-sm"
      @click="setPage(_total_participant_page)"
      :disabled="_participant_current_page == _total_participant_page"
    >
      <i class="bi bi-chevron-double-right"></i>
    </button>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "ParticipantListPaginationComp",
  computed: {
    ...mapGetters(["_total_participant_page", "_participant_current_page"]),
    rangePage() {
      return this._total_participant_page > 5
        ? this._participant_current_page + 2 > this._total_participant_page
          ? [
              this._total_participant_page - 4,
              this._total_participant_page - 3,
              this._total_participant_page - 2,
              this._total_participant_page - 1,
              this._total_participant_page,
            ]
          : this._participant_current_page - 2 < 1
          ? [1, 2, 3, 4, 5]
          : [
              this._participant_current_page - 2,
              this._participant_current_page - 1,
              this._participant_current_page,
              this._participant_current_page + 1,
              this._participant_current_page + 2,
            ]
        : [...Array(this._total_participant_page).keys()].map((i) => i + 1);
    },
  },
  methods: {
    setPage(page) {
      this.$store.commit("setParticipantCurrentPage", page);
      this.$store.dispatch("GET_PARTICIPANTS").catch((err) => {
        this.$swal({
          icon: "error",
          text: err.message,
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
