<template>
  <div class="modal fade" tabindex="-1" id="editParticipantModal">
    <div class="modal-dialog modal-xl modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Kişi Düzenle</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="tab-content">
              <div class="tab-pane fade" id="tab_1" role="tabpanel">
                <h3>Kişisel Bilgiler</h3>
                <div class="d-flex">
                  <fieldset class="field me-3">
                    <label class="form-label">
                      Ad Soyad
                      <span class="text-danger ms-1">*</span>
                    </label>
                    <input
                      class="form-control"
                      type="text"
                      v-model="_edit_participant.full_name" />
                  </fieldset>

                  <fieldset class="field me-3">
                    <label class="form-label">
                      Cinsiyet
                      <span class="text-danger ms-1">*</span>
                    </label>
                    <select
                      class="form-control"
                      v-model="_edit_participant.gender">
                      <option value="">Seçiniz</option>
                      <option value="Erkek">Erkek</option>
                      <option value="Kadın">Kadın</option>
                    </select>
                    <i class="material-icons-outlined dropdown-arrow"
                      >keyboard_arrow_down</i
                    >
                  </fieldset>

                  <fieldset class="field me-3">
                    <label class="form-label"> Grup </label>
                    <select
                      class="form-control"
                      v-model="_edit_participant.group">
                      <option v-bind:value="null">Seçiniz</option>
                      <option
                        v-for="group of _groups"
                        :value="group._id"
                        :key="group">
                        {{ group.text }}
                      </option>
                    </select>
                    <i class="material-icons-outlined dropdown-arrow"
                      >keyboard_arrow_down</i
                    >
                  </fieldset>
                  <fieldset class="field me-3">
                    <label class="form-label"> Branş </label>
                    <input
                      class="form-control"
                      type="text"
                      v-model="_edit_participant.branch" />
                  </fieldset>
                  <fieldset class="field me-3">
                    <label class="form-label"> Telefon </label>
                    <input
                      class="form-control phone-input"
                      type="text"
                      placeholder="+90 5XX XXX XXXX"
                      v-model="_edit_participant.telephone" />
                  </fieldset>
                  <fieldset class="field">
                    <label class="form-label">2. Telefon</label>
                    <input
                      class="form-control phone-input"
                      type="text"
                      placeholder="+90 5XX XXX XXXX"
                      v-model="_edit_participant.telephone_2" />
                  </fieldset>
                </div>
                <div class="d-flex">
                  <fieldset class="field me-3">
                    <label class="form-label"> E Posta </label>
                    <input
                      class="form-control"
                      type="email"
                      placeholder="ornek@arkadyas.com"
                      v-model="_edit_participant.mail" />
                  </fieldset>
                  <fieldset class="field me-3">
                    <label class="form-label"> 2. E Posta </label>
                    <input
                      class="form-control"
                      type="email"
                      placeholder="ornek@arkadyas.com"
                      v-model="_edit_participant.mail_2" />
                  </fieldset>
                  <fieldset class="field me-3">
                    <label class="form-label"> Kimlik Numarası </label>
                    <input
                      class="form-control"
                      type="text"
                      placeholder="00000000000"
                      v-model="_edit_participant.identification_number" />
                  </fieldset>
                  <fieldset class="field me-3">
                    <label class="form-label"> Pasaport Numarası </label>
                    <input
                      class="form-control"
                      type="text"
                      v-model="_edit_participant.passport_number"
                      placeholder="000000000" />
                  </fieldset>
                  <fieldset class="field">
                    <label class="form-label"> Doğum Tarihi </label>
                    <input
                      class="form-control"
                      type="date"
                      :value="
                        _edit_participant.date_of_birth
                          ? new Date(_edit_participant.date_of_birth)
                              .toISOString()
                              .slice(0, 10)
                          : ''
                      "
                      @input="
                        _edit_participant.date_of_birth = $event.target.value
                      " />
                  </fieldset>
                </div>
                <div class="d-flex">
                  <fieldset class="field me-3">
                    <label class="form-label"> Çalıştığı Kurum </label>
                    <input
                      class="form-control"
                      type="text"
                      v-model="_edit_participant.company" />
                  </fieldset>
                  <fieldset class="field me-3">
                    <label class="form-label"> Bölüm </label>
                    <input
                      class="form-control"
                      type="text"
                      v-model="_edit_participant.section" />
                  </fieldset>
                  <fieldset class="field me-3">
                    <label class="form-label"> Uzmanlık </label>
                    <input
                      class="form-control"
                      type="text"
                      v-model="_edit_participant.expertise" />
                  </fieldset>
                  <fieldset class="field">
                    <label class="form-label"> Ünvan </label>
                    <input
                      class="form-control"
                      type="text"
                      v-model="_edit_participant.title" />
                  </fieldset>
                </div>
                <div class="d-flex">
                  <fieldset class="field">
                    <label class="form-label"> Ülke </label>
                    <select
                      class="form-control"
                      v-model="_edit_participant.country">
                      <option v-bind:value="null">Seçiniz</option>
                      <option
                        v-for="country of Countries"
                        :value="country.name"
                        :key="country">
                        {{ country.name }}
                      </option>
                    </select>
                  </fieldset>
                  <fieldset class="field me-3">
                    <label class="form-label"> Şehir </label>
                    <select
                      class="form-control"
                      v-model="_edit_participant.city">
                      <option v-bind:value="null">Seçiniz</option>
                      <option
                        v-for="city of cities"
                        :disabled="_edit_participant.country == null"
                        :value="city"
                        :key="city">
                        {{ city }}
                      </option>
                    </select>
                  </fieldset>
                </div>
                <div class="d-flex">
                  <fieldset class="field me-3">
                    <label class="form-label"> İş Adresi </label>
                    <textarea
                      class="form-control"
                      v-model="_edit_participant.address"></textarea>
                  </fieldset>
                  <fieldset class="field">
                    <label class="form-label"> Ev Adresi </label>
                    <textarea
                      class="form-control"
                      v-model="_edit_participant.home_address"></textarea>
                  </fieldset>
                </div>
                <div class="d-flex">
                  <fieldset class="field flex-fill me-0">
                    <label class="form-label"> Not </label>
                    <textarea
                      class="form-control"
                      v-model="_edit_participant.note"></textarea>
                  </fieldset>
                </div>
                <div class="d-flex" v-if="_admin.role == 'super_admin'">
                  <fieldset class="field me-3">
                    <label class="form-label"> MAİL İzni </label>
                    <select
                      class="form-control"
                      v-model="_edit_participant.email_permission">
                      <option value="">Seçiniz</option>
                      <option value="pending">Beklemede</option>
                      <option value="true">İzin Verildi</option>
                      <option value="false">İzin Verilmedi</option>
                    </select>
                    <i class="material-icons-outlined dropdown-arrow"
                      >keyboard_arrow_down</i
                    >
                  </fieldset>
                  <fieldset class="field">
                    <label class="form-label"> SMS İzni </label>
                    <select
                      class="form-control"
                      v-model="_edit_participant.sms_permission">
                      <option value="">Seçiniz</option>
                      <option value="pending">Beklemede</option>
                      <option value="true">İzin Verildi</option>
                      <option value="false">İzin Verilmedi</option>
                    </select>
                    <i class="material-icons-outlined dropdown-arrow"
                      >keyboard_arrow_down</i
                    >
                  </fieldset>
                </div>
              </div>
              <div class="tab-pane fade" id="tab_2" role="tabpanel">
                <h3>Genel Bilgiler</h3>
                <div class="d-flex">
                  <fieldset class="field me-3">
                    <label class="form-label">Genel Bilgi 1</label>
                    <textarea
                      data-name="question_1"
                      rows="2"
                      class="form-control"
                      v-model="
                        _edit_participant.general_informations[0]
                      "></textarea>
                  </fieldset>
                  <fieldset class="field me-3">
                    <label class="form-label">Genel Bilgi 2</label>
                    <textarea
                      data-name="question_2"
                      rows="2"
                      class="form-control"
                      v-model="
                        _edit_participant.general_informations[1]
                      "></textarea>
                  </fieldset>
                  <fieldset class="field me-3">
                    <label class="form-label">Genel Bilgi 3</label>
                    <textarea
                      data-name="question_3"
                      rows="2"
                      class="form-control"
                      v-model="
                        _edit_participant.general_informations[2]
                      "></textarea>
                  </fieldset>
                  <fieldset class="field">
                    <label class="form-label">Genel Bilgi 4</label>
                    <textarea
                      data-name="question_4"
                      rows="2"
                      class="form-control"
                      v-model="
                        _edit_participant.general_informations[3]
                      "></textarea>
                  </fieldset>
                </div>
                <div class="d-flex mb-1">
                  <fieldset class="field me-3">
                    <label class="form-label">Genel Bilgi 5</label>
                    <textarea
                      data-name="question_5"
                      rows="2"
                      class="form-control"
                      v-model="
                        _edit_participant.general_informations[4]
                      "></textarea>
                  </fieldset>
                  <fieldset class="field me-3">
                    <label class="form-label">Genel Bilgi 6</label>
                    <textarea
                      data-name="question_6"
                      rows="2"
                      class="form-control"
                      v-model="
                        _edit_participant.general_informations[5]
                      "></textarea>
                  </fieldset>
                  <fieldset class="field me-3">
                    <label class="form-label">Genel Bilgi 7</label>
                    <textarea
                      data-name="question_7"
                      rows="2"
                      class="form-control"
                      v-model="
                        _edit_participant.general_informations[6]
                      "></textarea>
                  </fieldset>
                  <fieldset class="field">
                    <label class="form-label">Genel Bilgi 8</label>
                    <textarea
                      data-name="question_8"
                      rows="2"
                      class="form-control"
                      v-model="
                        _edit_participant.general_informations[7]
                      "></textarea>
                  </fieldset>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <div class="d-flex w-100 justify-content-between">
            <ul class="left d-flex nav nav-tabs border-0" role="tablist">
              <li class="nav-item show active" role="presentation">
                <button
                  data-bs-toggle="tab"
                  data-bs-target="#tab_1"
                  type="button"
                  role="tab"
                  aria-controls="tab_1"
                  aria-selected="true"
                  class="btn btn-secondary btn-sm btn-iconed m-2">
                  Kişisel Bilgiler
                  <i class="material-icons-outlined">badge</i>
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  data-bs-toggle="tab"
                  data-bs-target="#tab_2"
                  type="button"
                  role="tab"
                  aria-controls="tab_2"
                  aria-selected="false"
                  class="btn btn-secondary btn-sm btn-iconed m-2">
                  Genel Bilgiler
                  <i class="material-icons-outlined">more_horiz</i>
                </button>
              </li>
            </ul>
            <div class="right d-flex">
              <button
                type="button"
                class="btn btn-success small btn-sm btn-iconed update-btn m-2"
                :disabled="loading"
                @click="updateParticipant()">
                {{ loading ? "Güncelleniyor.." : "Güncelle" }}
                <i class="material-icons-outlined">save</i>
              </button>
              <button
                type="button"
                class="btn btn-sm btn-danger small btn-iconed m-2"
                data-bs-dismiss="modal">
                İptal
                <i class="material-icons-outlined">close</i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min.js";
import { mapGetters } from "vuex";

import Countries from "@/utils/Countries";

export default {
  name: "EditParticipantPopup",
  data() {
    return {
      loading: false,
      Countries,
      cities: [],
    };
  },
  computed: {
    ...mapGetters(["_edit_participant", "_groups", "_admin"]),
  },
  mounted() {
    this.$store.commit(
      "setEditParticipantPopup",
      new bootstrap.Modal("#editParticipantModal")
    );
    document.querySelector("[aria-controls='tab_1']").click();

    for (const country of this.Countries) {
      if (country.name == this._edit_participant.country) {
        this.cities = country.cities;
      }
      this.cities = [];
    }
  },
  watch: {
    "_edit_participant.country"(val) {
      for (const country of this.Countries) {
        if (country.name == val) {
          this.cities = country.cities;
          break;
        }
        this.cities = [];
      }
    },
  },
  methods: {
    updateParticipant() {
      this.loading = true;
      setTimeout(() => {
        this.$store
          .dispatch("UPDATE_PARTICIPANT", this._edit_participant)
          .then((res) => {
            this.$store.dispatch("GET_PARTICIPANTS").catch((err) => {
              this.$swal({
                icon: err?.status,
                text: err?.message,
              });
            });
            this.$store.dispatch("GET_BRANCHES_GROUPS").catch((err) => {
              this.$swal({
                icon: err?.status,
                text: err?.message,
              });
            });
            this.$swal({
              icon: "success",
              text: res.message,
            });
            this.$store.getters._edit_participant_popup.hide();
          })
          .catch((err) => {
            this.$swal({
              icon: "error",
              text: err?.message || err,
            });
          })
          .finally(() => (this.loading = false));
      }, 1000);
    },
  },
};
</script>
<style lang="scss" scoped></style>
