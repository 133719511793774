<template>
  <div class="alert alert-warning mb-0 d-flex align-items-center">
    <i class="material-icons-outlined me-3">warning</i>
    Kişi bulunamadı ya da aradığınız kriterlere uygun kişi bulunamadı filtreyi
    temizleyip lütfen tekrar deneyin.
  </div>
</template>
<script>
export default {
  name: "NotFoundParticipantComp",
};
</script>
<style lang="scss" scoped></style>
