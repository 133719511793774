<template>
  <div>
    <h1 style="text-align: center">Aradığınız Sayfa Bulunamadı</h1>
    <router-link :to="{ name: 'HomeView' }">Indeks'ye Dön</router-link>
    <img src="@/assets/images/not_found.svg" alt="not-found" width="400" />
  </div>
</template>
<script>
export default {
  name: "PageNotFound",
};
</script>
<style lang="scss" scoped>
div {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  a {
    display: inline-block;
    padding: 0.5rem 1rem;
    border-radius: 100px;
    background: #e62b4a;
    color: #ffffff;
    font-weight: 500;
    font-size: 0.9rem;
    margin: 1.5rem 0;
    text-decoration: none;
  }

  h1 {
    color: #323240;
  }
}
</style>
