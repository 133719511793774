import { createStore } from "vuex";
import admins from "./modules/admins";
import auth from "./modules/auth";
import popups from "./modules/popups";
import settings from "./modules/settings";
import participants from "./modules/participants";
import tasks from "./modules/tasks";
import mergeds_data from "./modules/mergeds_data";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    admins,
    auth,
    popups,
    settings,
    participants,
    tasks,
    mergeds_data,
  },
});
