import axios from "axios";

export default {
  state: {
    settings: {
      mail: {},
      sms: {},
      mail_content: "",
      mail_subject: "",
      sms_content: "",
      mail_credit: 0,
      sms_credit: 0,
      birth_sms_content: "",
      permission_mail_content: "",
      permission_mail_subject: "",
      permission_sms_content: "",
    },
  },
  getters: {
    _settings(context) {
      return context.settings;
    },
  },
  mutations: {
    setSettings(context, payload) {
      context.settings = payload;
    },
  },
  actions: {
    GET_SETTINGS(context) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/settings/get`, {
            headers: {
              Authorization: `Bearer ${context.getters._token}`,
            },
          })
          .then((res) => {
            context.commit("setSettings", {
              mail: res.data.settings.mail,
              sms: res.data.settings.sms,
              mail_content: res.data.settings.mail_content,
              mail_subject: res.data.settings.mail_subject,
              sms_content: res.data.settings.sms_content,
              mail_credit: res.data.settings.mail_credit,
              sms_credit: res.data.settings.sms_credit,
              birth_sms_content: res.data.settings.birth_sms_content,
              permission_mail_content:
                res.data.settings.permission_mail_content,
              permission_mail_subject:
                res.data.settings.permission_mail_subject,
              permission_sms_content: res.data.settings.permission_sms_content,
            });
            resolve(res.data);
          })
          .catch((err) => {
            reject(err?.response?.data || err);
          });
      });
    },
    UPDATE_MAIL_SETTING(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/settings/update_mail`, payload, {
            headers: {
              Authorization: `Bearer ${context.getters._token}`,
            },
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err?.response?.data || err);
          });
      });
    },
    UPDATE_SMS_SETTING(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/settings/update_sms`, payload, {
            headers: {
              Authorization: `Bearer ${context.getters._token}`,
            },
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err?.response?.data || err);
          });
      });
    },
    UPDATE_MAIL_CONTENT(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/settings/update_mail_content`, payload, {
            headers: {
              Authorization: `Bearer ${context.getters._token}`,
            },
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err?.response?.data || err);
          });
      });
    },
    UPDATE_SMS_CONTENT(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/settings/update_sms_content`, payload, {
            headers: {
              Authorization: `Bearer ${context.getters._token}`,
            },
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err?.response?.data || err);
          });
      });
    },
    UPDATE_BIRTH_CONTENT(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/settings/update_birth_content`, payload, {
            headers: {
              Authorization: `Bearer ${context.getters._token}`,
            },
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err?.response?.data || err);
          });
      });
    },
    UPDATE_PERMISSION_CONTENT(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/settings/update_permission_content`, payload, {
            headers: {
              Authorization: `Bearer ${context.getters._token}`,
            },
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err?.response?.data || err);
          });
      });
    },
  },
};
