<template>
  <div class="loading">
    <i class="material-icons-outlined">loop</i>
    Yükleniyor
  </div>
</template>
<script>
export default {
  name: "LoaderComp",
};
</script>
<style lang="scss" scoped>
.loading {
  position: fixed;
  inset: 0;
  background: rgb(var(--color-light));
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
  font-weight: 600;
  font-size: 1.25rem;
  color: rgb(var(--color-muted));
  transition: all 300ms ease-in-out;
  &.hide {
    opacity: 0;
    visibility: hidden;
    transform: scale(1.1) translateY(5%) skewX(-10deg);
  }
  i {
    color: rgb(var(--color-primary));
    font-size: 2.5rem;
    margin-right: 1rem;
    animation: spin 1s ease-in-out infinite;
  }
}

@keyframes spin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
</style>
