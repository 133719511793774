<template>
  <div class="modal fade" id="sendSmsModal" data-focus="false">
    <div class="modal-dialog modal-lg modal-dialog-scrollable">
      <form class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Kişilere Sms Gönder</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div
          class="modal-body"
          :class="{ blur: update_sms_content_loading || send_sms_loading }"
        >
          <div>
            <div class="d-flex">
              <fieldset class="field flex-fill">
                <label class="form-label">Mevcut Paketiniz</label>
                <input
                  class="form-control"
                  type="text"
                  value="Varsayılan"
                  readonly
                />
              </fieldset>
              <fieldset class="field flex-fill me-3">
                <label class="form-label">Paket içeriğindeki mail Limiti</label>
                <input class="form-control" type="text" value="500" readonly />
              </fieldset>
              <fieldset class="field flex-fill">
                <label class="form-label">Paket içeriğindeki sms Limiti</label>
                <input class="form-control" type="text" value="500" readonly />
              </fieldset>
            </div>
            <hr class="hr" />
            <div class="d-flex align-items-center">
              <fieldset class="field flex-fill me-3">
                <label class="form-label">Kalan sms krediniz</label>
                <input
                  class="form-control"
                  type="number"
                  :value="_settings.sms_credit"
                  readonly
                />
              </fieldset>
              <fieldset class="field flex-fill me-3">
                <label class="form-label">Sms kimlere gidecek?</label>
                <select class="form-control" v-model="branch">
                  <option
                    value=""
                    :disabled="_settings.sms_credit < _total_participant"
                  >
                    Tüm Kişiler - ({{ _total_participant }} Kişi)
                  </option>
                  <option
                    v-for="branch of _branches"
                    :value="branch.branch"
                    :key="branch"
                    :disabled="_settings.sms_credit < branch.count"
                  >
                    {{ branch.branch }} - ({{ branch.count }} Kişi)
                  </option>
                </select>
              </fieldset>
              <fieldset class="field flex-fill">
                <label class="form-label">Grup Seç (*)</label>
                <select class="form-control" v-model="group">
                  <option
                    value=""
                    :disabled="_settings.mail_credit < _total_participant"
                  >
                    Tüm Kişiler - ({{ _total_participant }} Kişi)
                  </option>
                  <option
                    v-for="group of _groups"
                    :value="group._id"
                    :key="group"
                  >
                    {{ group.text }}
                  </option>
                </select>
              </fieldset>
            </div>
            <p class="text-small fw-500 mb-3 p-3 rounded bg-gray">
              Grup seçeneğinden seçim yapmanız dahilinde Branş seçiminiz devre
              dışı kalıcaktır.
            </p>
            <p class="text-small fw-500 mb-3 p-3 rounded bg-gray">
              Kredinizin Yetmediği Branşları seçemezsiniz. Sıkıntı yaşıyorsanız
              Yetkili ile iletişime geçiniz.
            </p>
            <hr />
            <p class="text-small fw-500 my-3 p-3 rounded bg-gray">
              Sms içeriğinde kişinin ad soyadı için
              <code>{name}</code>, e posta adresi için <code>{email}</code> ve
              telefon numarası için <code>{phone}</code> kullanılabilir.
            </p>
            <div class="d-flex">
              <fieldset class="field flex-fill me-0">
                <label class="form-label">Sms İçeriği</label>
                <textarea
                  class="form-control"
                  v-model="_settings.sms_content"
                ></textarea>
              </fieldset>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-sm btn-danger btn-iconed"
            data-bs-dismiss="modal"
          >
            İptal
            <i class="material-icons-outlined">close</i>
          </button>
          <button
            type="button"
            class="btn btn-success btn-sm btn-iconed update-btn"
            @click="updateSmsContent()"
            :disabled="update_sms_content_loading"
          >
            {{
              update_sms_content_loading
                ? "SMS Bilgileri Kaydediliyor"
                : "SMS Bilgilerini Kaydet"
            }}

            <i class="material-icons-outlined">save_as</i>
          </button>

          <button
            type="button"
            class="btn btn-success btn-sm btn-iconed update-btn"
            @click="sendSms()"
            :disabled="send_sms_loading"
          >
            {{ send_sms_loading ? "Sms Gönderiliyor.." : "Sms Gönder" }}
            <i class="material-icons-outlined">sms</i>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min";
import { mapGetters } from "vuex";

export default {
  name: "SendSmsPopup",
  computed: {
    ...mapGetters(["_total_participant", "_settings", "_branches", "_groups"]),
  },
  mounted() {
    this.$store.commit("setSendSmsPopup", new bootstrap.Modal("#sendSmsModal"));
  },
  data() {
    return {
      update_sms_content_loading: false,
      send_sms_loading: false,
      branch: "",
      group: "",
    };
  },
  methods: {
    updateSmsContent() {
      this.update_sms_content_loading = true;
      setTimeout(() => {
        this.$store
          .dispatch("UPDATE_SMS_CONTENT", {
            sms_content: this._settings.sms_content,
          })
          .then((res) => {
            this.$swal({
              icon: "success",
              text: res.message,
            });
          })
          .catch((err) => {
            this.$swal({
              icon: "error",
              text: err?.message || err,
            });
          })
          .finally(() => (this.update_sms_content_loading = false));
      }, 1000);
    },
    sendSms() {
      this.send_sms_loading = true;
      setTimeout(() => {
        this.$store
          .dispatch("SEND_SMS", {
            sms_content: this._settings.sms_content,
            branch: this.branch,
            group: this.group,
          })
          .then((res) => {
            this.$swal({
              icon: "success",
              text: res.message,
            });
            this.$store.getters._send_sms_popup.hide();
            this.$store.dispatch("GET_TASKS");
          })
          .catch((err) => {
            this.$swal({
              icon: "error",
              text: err?.message || err,
            });
          })
          .finally(() => (this.send_sms_loading = false));
      }, 1000);
    },
  },
};
</script>
<style lang="scss" scoped>
.bg-gray {
  background-color: #e7e7e7;
}
</style>
