import axios from "axios";

export default {
  state: {
    admins: [],
    admin_loading: false,
  },
  getters: {
    _admins(state) {
      return state.admins;
    },
    _admin_loading(context) {
      return context.admin_loading;
    },
  },
  mutations: {
    setAdmins(state, payload) {
      state.admins = payload;
    },
    setAdminLoading(state, payload) {
      state.admin_loading = payload;
    },
  },
  actions: {
    GET_ADMIN(context, payload) {
      return new Promise((resolve, reject) => {
        context.commit("setAdminLoading", true);
        setTimeout(() => {
          axios
            .get(`/admins/get/${payload.id}`, {
              headers: {
                Authorization: `Bearer ${context.getters._token}`,
              },
            })
            .then((res) => {
              resolve(res.data);
            })
            .catch((err) => {
              reject(err?.response?.data || err);
            })
            .finally(() => context.commit("setAdminLoading", false));
        }, 500);
      });
    },
    GET_ADMINS(context) {
      return new Promise((resolve, reject) => {
        context.commit("setAdminLoading", true);
        setTimeout(() => {
          axios
            .get("/admins/list", {
              headers: {
                Authorization: `Bearer ${context.getters._token}`,
              },
            })
            .then((res) => {
              context.commit("setAdmins", res.data.admins);
              resolve(res.data);
            })
            .catch((err) => {
              context.commit("setAdmins", []);
              reject(err?.response?.data || err);
            })
            .finally(() => context.commit("setAdminLoading", false));
        }, 500);
      });
    },
    CREATE_ADMIN(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/admins/create", payload, {
            headers: {
              Authorization: `Bearer ${context.getters._token}`,
            },
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => reject(err?.response?.data || err));
      });
    },
    UPDATE_ADMIN(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/admins/update/${payload.id}`, payload, {
            headers: {
              Authorization: `Bearer ${context.getters._token}`,
            },
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => reject(err?.response?.data || err));
      });
    },
    DELETE_ADMIN(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/admins/delete/${payload.id}`, {
            headers: {
              Authorization: `Bearer ${context.getters._token}`,
            },
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => reject(err?.response?.data || err));
      });
    },
    SET_GROUP_ADMIN(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/admins/set_group", payload, {
            headers: {
              Authorization: `Bearer ${context.getters._token}`,
            },
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => reject(err?.response?.data || err));
      });
    },
  },
};
