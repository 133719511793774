<template>
  <transition name="loader" mode="out-in">
    <div v-if="!loader" class="app-wrapper">
      <transition name="blur" mode="out-in">
        <Header
          v-if="
            _auth &&
            $route.name != 'NotFound' &&
            $route.name != 'SmsPermissionView'
          "
        />
      </transition>

      <router-view v-slot="{ Component, route }">
        <transition name="router" mode="out-in">
          <div :key="route.name">
            <component :is="Component" />
          </div>
        </transition>
      </router-view>

      <transition name="blur" mode="out-in">
        <Tasks
          v-if="
            _auth &&
            $route.name != 'NotFound' &&
            $route.name != 'SmsPermissionView' &&
            (_tasks.length > 0 || _local_tasks.length > 0)
          "
        />
      </transition>
    </div>
    <Loader v-else />
  </transition>
</template>

<script>
import Header from "@/components/shared/Header";
import Tasks from "@/components/shared/Tasks";
import Loader from "@/components/Loader";
import { mapGetters } from "vuex";

export default {
  name: "App",
  data: () => ({
    loader: true,
  }),
  components: {
    Header,
    Tasks,
    Loader,
  },
  computed: {
    ...mapGetters(["_auth", "_token", "_tasks", "_local_tasks"]),
  },
  beforeCreate() {
    this.$store.dispatch("SET_TOKEN", localStorage.getItem("AUTH_TOKEN") || "");
    this.$router.beforeEach((to, from, next) => {
      if (to.meta.auth && !this._token) {
        return next({ name: "LoginView" });
      } else if (to.meta.auth && this._token) {
        return this.$store
          .dispatch("CHECK_AUTH")
          .then(() => next())
          .catch((err) => {
            next({ name: "LoginView" });
            return this.$swal({
              icon: "error",
              text: err.message,
            });
          });
      }
      if (to.name === "LoginView" && this._token) return next("/");
      next();
    });
  },
  mounted() {
    setTimeout(() => (this.loader = false), 1000);
  },
};
</script>

<style lang="scss" scoped>
.app-wrapper {
  display: flex;
  flex-direction: column;
}
</style>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Material+Icons+Outlined");
@import url("https://fonts.googleapis.com/css2?family=Material+Icons+Two+Tone");
@import url("https://fonts.googleapis.com/css2?family=Material+Icons");

body {
  margin: 0;
  padding: 0;
  overflow: auto;
}

.row {
  --bs-gutter-x: 0rem !important;
}

.content {
  padding: 30px;
}

.loader-enter-active,
.loader-leave-active {
  transition: opacity 0.5s ease;
}

.loader-enter-from,
.loader-leave-to {
  opacity: 0;
}

.blur-enter-from {
  opacity: 0;
  filter: blur(12px);
}
.blur-enter-to,
.blur-leave-from {
  opacity: 1;
  filter: blur(0) grayscale(0);
}
.blur-leave-to {
  opacity: 0;
  filter: blur(12px);
}
.blur-enter-active,
.blur-leave-active {
  transition: all 450ms ease-in-out;
}

.router-enter-from {
  opacity: 0;
  filter: blur(12px);
}
.router-enter-to,
.router-leave-from {
  opacity: 1;
  filter: blur(0) grayscale(0);
}
.router-leave-to {
  opacity: 0;
  filter: blur(12px);
}
.router-enter-active,
.router-leave-active {
  transition: all 450ms ease-in-out;
}
</style>
