<template>
  <div class="container mt-3 home">
    <HomeButtons />

    <Filter />

    <ParticipantList />

    <ExcelUpload />
  </div>

  <SendMailPopup v-if="_settings.mail_credit > 0" />
  <SendSmsPopup v-if="_settings.sms_credit > 0" />
  <EditParticipantPopup />
  <CreateParticipantPopup />
  <AdvancedReportPopup />
  <PermissionSendPopup v-if="_admin.role == 'super_admin'" />
</template>

<script>
import SendMailPopup from "@/components/popups/MailSend";
import SendSmsPopup from "@/components/popups/SmsSend";
import EditParticipantPopup from "@/components/popups/EditParticipant";
import CreateParticipantPopup from "@/components/popups/CreateParticipant";
import PermissionSendPopup from "@/components/popups/PermissionSend";
import AdvancedReportPopup from "@/components/popups/AdvancedReport";

import ExcelUpload from "@/components/HomeView/ExcelUpload";
import Filter from "@/components/HomeView/Filter";
import ParticipantList from "@/components/HomeView/ParticipantList";
import HomeButtons from "@/components/HomeView/HomeButtons";

import { mapGetters } from "vuex";

export default {
  name: "HomeView",
  computed: {
    ...mapGetters(["_admin", "_participants", "_settings"]),
  },
  components: {
    SendMailPopup,
    SendSmsPopup,
    EditParticipantPopup,
    CreateParticipantPopup,
    PermissionSendPopup,
    AdvancedReportPopup,
    ExcelUpload,
    Filter,
    ParticipantList,
    HomeButtons,
  },
  beforeCreate() {
    this.$store.dispatch("GET_PARTICIPANTS").catch((err) => {
      this.$swal({
        icon: err?.status,
        text: err?.message,
      });
    });
    this.$store.dispatch("GET_BRANCHES_GROUPS").catch((err) => {
      this.$swal({
        icon: err?.status,
        text: err?.message,
      });
    });
    this.$store.dispatch("GET_SETTINGS").catch((err) => {
      this.$swal({
        icon: err?.status,
        text: err?.message,
      });
    });
  },
};
</script>

<style lang="scss" scoped></style>
