<template>
  <transition name="blur" mode="out-in">
    <div class="event-table-container mb-3" v-if="!_participants_loading">
      <NotFoundParticipants v-if="_participants.length == 0" />
      <table class="table event-table" v-else>
        <thead>
          <tr>
            <th>MAİL/SMS İZNİ</th>
            <th>Ad Soyad</th>
            <th>Telefon</th>
            <th>Mail</th>
            <th>Grup</th>
            <th>Düzenle</th>
            <th>Sil</th>
            <th>Mail</th>
            <th>Sms</th>
          </tr>
        </thead>

        <tbody>
          <tr
            class="participant-tr"
            v-for="participant of _participants"
            :key="participant"
          >
            <td style="color: #aaa">
              <div
                class="d-flex align-items-center justify-content-center gap-3"
              >
                <i
                  class="material-icons"
                  :class="{
                    'text-success': participant.email_permission == 'true',
                    'text-danger': participant.email_permission == 'false',
                    'text-secondary': participant.email_permission == 'pending',
                  }"
                  >email</i
                >
                <i
                  class="material-icons"
                  :class="{
                    'text-success': participant.sms_permission == 'true',
                    'text-danger': participant.sms_permission == 'false',
                    'text-secondary': participant.sms_permission == 'pending',
                  }"
                  >sms</i
                >
              </div>
            </td>
            <td>{{ participant.full_name }}</td>
            <td>{{ participant.telephone }}</td>
            <td>{{ participant.mail }}</td>
            <td class="text-center fw-bold text-primary text-uppercase">
              {{ participant.group?.text }}
            </td>
            <td width="50">
              <div class="d-flex">
                <button
                  @click="editParticipant(participant._id)"
                  class="btn btn-secondary btn-sm update-participant-modal-btn ms-auto me-2"
                >
                  <i class="material-icons-outlined">edit</i>
                </button>
              </div>
            </td>
            <td width="50">
              <div class="d-flex">
                <button
                  class="btn btn-danger btn-sm delete-participant-btn me-auto ms-2"
                  @click="deleteParticipant(participant._id)"
                  :disabled="_admin.role == 'admin'"
                >
                  <i class="material-icons-outlined">delete</i>
                </button>
              </div>
            </td>
            <td width="50">
              <div class="d-flex">
                <button
                  class="btn btn-success btn-sm delete-participant-btn me-auto ms-2"
                  @click="
                    participantSendMail(
                      participant._id,
                      participant.mail ? true : false,
                      participant.email_permission == 'true' ? true : false
                    )
                  "
                  :disabled="
                    participant.email_permission != 'true' || !participant.mail
                      ? true
                      : false
                  "
                >
                  <i class="material-icons-outlined">mail</i>
                </button>
              </div>
            </td>
            <td width="50">
              <div class="d-flex">
                <button
                  class="btn btn-success btn-sm delete-participant-btn me-auto me-2"
                  @click="
                    participantSendSms(
                      participant._id,
                      participant.telephone ? true : false,
                      participant.sms_permission == 'true' ? true : false
                    )
                  "
                  :disabled="
                    participant.sms_permission != 'true' ||
                    !participant.telephone
                      ? true
                      : false
                  "
                >
                  <i class="material-icons-outlined">sms</i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ParticipantLoader v-else />
  </transition>

  <ParticipantListPagination v-if="_participants.length > 0" />

  <ParticipantMailSend />
  <ParticipantSmsSend />
</template>
<script>
import { mapGetters } from "vuex";

import ParticipantListPagination from "./ParticipantListPagination";
import ParticipantLoader from "./ParticipantLoader";
import NotFoundParticipants from "./NotFoundParticipants";
import ParticipantMailSend from "@/components/popups/ParticipantMailSend";
import ParticipantSmsSend from "@/components/popups/ParticipantSmsSend";

export default {
  name: "ParticipantListComp",
  components: {
    ParticipantListPagination,
    ParticipantLoader,
    NotFoundParticipants,
    ParticipantMailSend,
    ParticipantSmsSend,
  },
  computed: {
    ...mapGetters(["_admin", "_participants", "_participants_loading"]),
  },
  methods: {
    participantSendMail(id, exist_mail, permission) {
      if (exist_mail && permission) {
        this.$store
          .dispatch("GET_PARTICIPANT", id)
          .then(() => {
            this.$store.getters._participant_send_mail_popup.show();
          })
          .catch((err) => {
            this.$swal({
              icon: "error",
              text: err?.message || err,
            });
            this.$store.getters._participant_send_mail_popup.hide();
          });
      } else if (!exist_mail) {
        this.$swal({
          icon: "warning",
          text: "Bu Kişiye ait mail adresi bulunmadığından mail gönderemezsiniz.",
        });
      } else if (!permission) {
        this.$swal({
          icon: "warning",
          text: "Bu kişi mail gönderilmesine izin vermediğinden mail gönderemezsiniz.",
        });
      }
    },
    participantSendSms(id, exist_mail, permission) {
      if (exist_mail && permission) {
        this.$store
          .dispatch("GET_PARTICIPANT", id)
          .then(() => {
            this.$store.getters._participant_send_sms_popup.show();
          })
          .catch((err) => {
            this.$swal({
              icon: "error",
              text: err?.message || err,
            });
            this.$store.getters._participant_send_sms_popup.hide();
          });
      } else if (!exist_mail) {
        this.$swal({
          icon: "warning",
          text: "Bu Kişiye ait telefon numarası bulunmadığından sms gönderemezsiniz.",
        });
      } else if (!permission) {
        this.$swal({
          icon: "warning",
          text: "Bu kişi sms gönderilmesine izin vermediğinden sms gönderemezsiniz.",
        });
      }
    },
    editParticipant(id) {
      this.$store
        .dispatch("GET_PARTICIPANT", id)
        .then(() => {
          this.$store.getters._edit_participant_popup.show();
        })
        .catch((err) => {
          this.$swal({
            icon: "error",
            text: err?.message || err,
          });
          this.$store.getters._edit_participant_popup.hide();
        });
    },
    deleteParticipant(id) {
      if (this._admin.role == "super_admin") {
        this.$swal({
          title: "Emin misiniz?",
          text: "Bir kullanıcıyı silmek üzeresiniz, bu işlem geri döndürülemez",
          icon: "warning",
          showCancelButton: true,
          showConfirmButton: true,
          toast: false,
          position: "",
          confirmButtonText: "Sil",
          cancelButtonText: "İptal",
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store
              .dispatch("DELETE_PARTICIPANT", { id })
              .then((res) => {
                this.$store.dispatch("GET_PARTICIPANTS").catch((err) => {
                  this.$swal({
                    icon: err?.status,
                    text: err?.message,
                  });
                });
                this.$store.dispatch("GET_BRANCHES_GROUPS").catch((err) => {
                  this.$swal({
                    icon: err?.status,
                    text: err?.message,
                  });
                });
                this.$swal({
                  icon: "success",
                  text: res.message,
                });
              })
              .catch((err) => {
                this.$swal({
                  icon: "error",
                  text: err?.message || err,
                });
              });
          }
        });
      } else {
        this.$swal({
          icon: "warning",
          text: "Bu işlemi yapmaya yetkiniz bulunmamaktadır. Lütfen yetkili ile iletişime geçiniz.",
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.event-table-container {
  overflow-x: auto;
  box-shadow: 2px 4px 16px -2px rgba(var(--bs-dark-rgb), 0.15);
  .event-table {
    border: 1px solid #e5e5e5;
    background: white;
    width: 100%;
    margin-bottom: 0 !important;
    max-width: 100%;

    &.table {
      thead {
        text-transform: uppercase;
        font-size: 0.9rem;
        color: rgb(var(--color-primary));
        th {
          width: max-content;
          white-space: nowrap;
          text-align: center;
          color: rgb(var(--color-blue));
        }
      }

      tr {
        width: 100%;
      }
    }
  }
}
</style>
