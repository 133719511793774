import axios from "axios";

export default {
  state: {
    mergeds_data: [],
  },
  getters: {
    _mergeds_data(state) {
      return state.mergeds_data;
    },
  },
  mutations: {
    setMergedsData(state, payload) {
      state.mergeds_data = payload;
    },
  },
  actions: {
    GET_MERGED_DATAS(context) {
      return new Promise((resolve, reject) => {
        context.commit("setParticipantLoading", true);
        setTimeout(() => {
          axios
            .get(`/mergeds_data/getAll`, {
              headers: {
                Authorization: `Bearer ${context.getters._token}`,
              },
            })
            .then((res) => {
              context.commit("setMergedsData", res.data.mergeds_data);
              resolve(res.data);
            })
            .catch((err) => {
              context.commit("setMergedsData", []);
              reject(err?.response?.data || err);
            })
            .finally(() => context.commit("setParticipantLoading", false));
        }, 500);
      });
    },

    MERGE_PARTICIPANT(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `/mergeds_data/put/${payload.id}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${context.getters._token}`,
              },
            }
          )
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err?.response?.data || err);
          });
      });
    },

    DELETE_MERGE(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/mergeds_data/delete/${payload.id}`, {
            headers: {
              Authorization: `Bearer ${context.getters._token}`,
            },
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err?.response?.data || err);
          });
      });
    },
  },
};
