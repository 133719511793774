<template>
  <header class="company-header">
    <div class="container h-100">
      <div class="d-flex align-items-center justify-content-between h-100">
        <span class="text-small text-primary m-0 me-2">v2.0.0</span>
        <span
          :to="{ name: 'HomeView' }"
          class="material-icons-outlined text-primary"
          >business</span
        >
        <div class="hello ms-3 me-auto">
          Hoşgeldiniz,
          <strong class="text-secondary">{{ _admin?.full_name }}</strong>
        </div>

        <nav v-if="_admin?.role == 'super_admin'" class="me-auto">
          <ul>
            <li>
              <router-link :to="{ name: 'HomeView' }">
                <i class="material-icons-outlined">home</i>
                Ana Sayfa
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'MergedsView' }">
                <i class="material-icons-outlined">merge</i>
                Birleştirme İşlemleri
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'AdminsView' }">
                <i class="material-icons-outlined">groups</i>
                Personeller
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'SettingsView' }">
                <i class="material-icons-outlined">manage_accounts</i>
                Ayarlar
              </router-link>
            </li>
          </ul>
        </nav>

        <button @click="logout()" class="btn btn-sm btn-danger btn-iconed">
          Çıkış Yap
          <i class="material-icons-outlined ms-2">exit_to_app</i>
        </button>
      </div>
    </div>
  </header>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "HeaderComp",
  computed: {
    ...mapGetters(["_admin"]),
  },
  methods: {
    logout() {
      this.$store.dispatch("LOGOUT");
      this.$router.push({ name: "LoginView" });
      this.$swal({
        icon: "success",
        text: "Oturumunuz Sonlandırılmıştır, iyi günler.",
      });
    },
  },
  mounted() {
    this.$store.dispatch("GET_TASKS");
    setInterval(() => {
      this.$store.dispatch("GET_TASKS").catch(() => {});
    }, 5000);
  },
};
</script>
<style lang="scss" scoped>
header.company-header {
  height: 60px;
  background: #ffffff;
  box-shadow: 2px 4px 18px -2px rgba(120, 120, 120, 0.2);

  nav {
    height: inherit;

    ul {
      height: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
      list-style: none;

      li {
        a {
          display: inline-flex;
          align-items: center;
          justify-content: space-between;
          position: relative;
          margin: 0 1rem;
          font-weight: 600;
          font-size: 0.9rem;
          color: rgb(var(--color-muted));
          padding: 0.5rem 1rem;
          text-decoration: none;
          transition: all 200ms ease-in-out;

          i {
            font-size: 1.25rem;
            margin-right: 0.5rem;
            transition: inherit;
          }

          &.router-link-active {
            color: rgb(var(--color-dark));

            &::before {
              transform: scaleX(1);
            }
            i {
              color: rgb(var(--color-primary));
            }
          }

          &::before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 2px;
            background-color: rgb(var(--color-primary));
            transform-origin: left;
            transform: scaleX(0);
            transition: inherit;
          }

          &:hover {
            color: rgb(var(--color-dark));

            &::before {
              transform: scaleX(1);
            }
            i {
              color: rgb(var(--color-primary));
            }
          }
        }
      }
    }
  }
}
</style>
