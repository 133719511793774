<template>
  <div class="modal fade" id="sendMailModal" data-focus="false">
    <div class="modal-dialog modal-lg modal-dialog-scrollable">
      <form class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Kişilere Mail Gönder</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"></button>
        </div>
        <div
          class="modal-body"
          :class="{ blur: update_mail_content_loading || send_mail_loading }">
          <div>
            <div class="d-flex">
              <fieldset class="field flex-fill">
                <label class="form-label">Mevcut Paketiniz</label>
                <input
                  class="form-control"
                  type="text"
                  value="Varsayılan"
                  readonly />
              </fieldset>
              <fieldset class="field flex-fill me-3">
                <label class="form-label">Paket içeriğindeki mail Limiti</label>
                <input class="form-control" type="text" value="500" readonly />
              </fieldset>
              <fieldset class="field flex-fill">
                <label class="form-label">Paket içeriğindeki sms Limiti</label>
                <input class="form-control" type="text" value="500" readonly />
              </fieldset>
            </div>
            <hr class="hr" />
            <div class="d-flex align-items-center">
              <fieldset class="field flex-fill">
                <label class="form-label">Kalan mail krediniz</label>
                <input
                  class="form-control"
                  type="number"
                  :value="_settings.mail_credit"
                  readonly />
              </fieldset>
              <fieldset class="field flex-fill me-3">
                <label class="form-label">Branş Seç</label>
                <select class="form-control" v-model="branch">
                  <option
                    value=""
                    :disabled="_settings.mail_credit < _total_participant">
                    Tüm Kişiler - ({{ _total_participant }} Kişi)
                  </option>
                  <option
                    v-for="branch of _branches"
                    :value="branch.branch"
                    :key="branch"
                    :disabled="_settings.mail_credit < branch.count">
                    {{ branch.branch }} - ({{ branch.count }} Kişi)
                  </option>
                </select>
              </fieldset>
              <fieldset class="field flex-fill">
                <label class="form-label">Grup Seç (*)</label>
                <select class="form-control" v-model="group">
                  <option
                    value=""
                    :disabled="_settings.mail_credit < _total_participant">
                    Tüm Kişiler - ({{ _total_participant }} Kişi)
                  </option>
                  <option
                    v-for="group of _groups"
                    :value="group._id"
                    :key="group">
                    {{ group.text }}
                  </option>
                </select>
              </fieldset>
            </div>
            <p class="text-small fw-500 mb-3 p-3 rounded bg-gray">
              Grup seçeneğinden seçim yapmanız dahilinde Branş seçiminiz devre
              dışı kalıcaktır.
            </p>
            <p class="text-small fw-500 mb-3 p-3 rounded bg-gray">
              Kredinizin Yetmediği Branşları seçemezsiniz. Sıkıntı yaşıyorsanız
              Yetkili ile iletişime geçiniz.
            </p>
            <hr />
            <div class="d-flex">
              <fieldset class="field flex-fill me-0">
                <label class="form-label">Mail Konusu</label>
                <input
                  class="form-control"
                  type="text"
                  v-model="_settings.mail_subject" />
              </fieldset>
            </div>
            <p class="text-small fw-500 my-3 p-3 rounded bg-gray">
              Mail içeriğinde kişinin ad soyadı için
              <code>{name}</code>, e posta adresi için <code>{email}</code> ve
              telefon numarası için <code>{phone}</code> kullanılabilir.
            </p>
            <div class="d-flex">
              <fieldset class="field flex-fill me-0">
                <label class="form-label">Mail İçeriği</label>
                <div id="editorjs2"></div>
              </fieldset>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-sm btn-danger btn-iconed"
            data-bs-dismiss="modal">
            İptal
            <i class="material-icons-outlined">close</i>
          </button>
          <button
            type="button"
            class="btn btn-success btn-sm btn-iconed update-btn"
            @click="updateMailContent()"
            :disabled="update_mail_content_loading">
            {{
              update_mail_content_loading
                ? "Mail Bilgileri Kaydediliyor"
                : "Mail Bilgilerini Kaydet"
            }}

            <i class="material-icons-outlined">save_as</i>
          </button>

          <button
            type="button"
            class="btn btn-success btn-sm btn-iconed update-btn"
            @click="sendMail()"
            :disabled="send_mail_loading">
            {{ send_mail_loading ? "Mail Gönderiliyor.." : "Mail Gönder" }}
            <i class="material-icons-outlined">email</i>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min";
import { mapGetters } from "vuex";
import axios from "axios";

import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import Table from "@editorjs/table";
import Underline from "@editorjs/underline";
import AlignmentTuneTool from "editorjs-text-alignment-blocktune";
import ImageTool from "@editorjs/image";

export default {
  name: "SendEmailPopup",
  computed: {
    ...mapGetters(["_total_participant", "_settings", "_branches", "_groups"]),
  },
  components: {},
  data() {
    return {
      update_mail_content_loading: false,
      send_mail_loading: false,
      branch: "",
      group: "",
      editor: null,
      blocks: [],
    };
  },
  mounted() {
    this.$store.commit(
      "setSendMailPopup",
      new bootstrap.Modal("#sendMailModal")
    );

    this.editorLoad();
  },
  watch: {
    blocks() {
      this.editor.blocks.render({
        blocks: this.blocks,
      });
    },
  },
  methods: {
    async updateMailContent() {
      this._settings.mail_content = (await this.editor.save()).blocks;
      this.update_mail_content_loading = true;
      setTimeout(() => {
        this.$store
          .dispatch("UPDATE_MAIL_CONTENT", {
            mail_content: this._settings.mail_content,
            mail_subject: this._settings.mail_subject,
          })
          .then((res) => {
            this.$swal({
              icon: "success",
              text: res.message,
            });
          })
          .catch((err) => {
            this.$swal({
              icon: "error",
              text: err?.message || err,
            });
          })
          .finally(() => (this.update_mail_content_loading = false));
      }, 1000);
    },
    async sendMail() {
      this._settings.mail_content = (await this.editor.save()).blocks;
      this.send_mail_loading = true;
      setTimeout(() => {
        this.$store
          .dispatch("SEND_MAIL", {
            mail_content: this._settings.mail_content,
            mail_subject: this._settings.mail_subject,
            branch: this.branch,
            group: this.group,
          })
          .then((res) => {
            this.$swal({
              icon: "success",
              text: res.message,
            });
            this.$store.getters._send_mail_popup.hide();
            this.$store.dispatch("GET_TASKS");
          })
          .catch((err) => {
            this.$swal({
              icon: "error",
              text: err?.message || err,
            });
          })
          .finally(() => (this.send_mail_loading = false));
      }, 1000);
    },
    onReady() {
      this.blocks = this._settings.mail_content;
    },
    async editorLoad() {
      this.editor = new EditorJS({
        placeholder: "Yazmaya başlayın...",
        autofocus: true,
        holder: "editorjs2",
        i18n: {
          messages: {
            ui: {
              blockTunes: {
                toggler: {
                  "Click to tune": "Düzenlemek için tıklayın",
                  "or drag to move": "veya taşımak için sürükleyin",
                },
              },
              inlineToolbar: {
                converter: {
                  "Convert to": "Dönüştür",
                },
                Bold: "Kalın",
                Italic: "İtalik",
                Link: "Bağlantı ekle",
                Underline: "Altı çizili",
              },
              toolbar: {
                toolbox: {
                  Add: "Ekle",
                },
              },
              popover: {
                Filter: "Filtrele",
                "Nothing found": "Bulunamadı",
              },
            },
            blockTunes: {
              delete: {
                Delete: "Sil",
                "Click to delete": "Silmek için tıklayın",
              },
              moveUp: {
                "Move up": "Yukarı taşı",
              },
              moveDown: {
                "Move down": "Aşağı taşı",
              },
            },
            toolNames: {
              Text: "Metin",
              Table: "Tablo",
              List: "Liste",
              Heading: "Başlık",
              Alert: "Uyarı",
            },
            tools: {
              header: {
                "Heading 1": "Başlık 1",
                "Heading 2": "Başlık 2",
                "Heading 3": "Başlık 3",
                "Heading 4": "Başlık 4",
                "Heading 5": "Başlık 5",
                "Heading 6": "Başlık 6",
              },
              list: {
                Unordered: "Sırasız liste",
                Ordered: "Sıralı liste",
              },
              table: {
                "With headings": "Başlıklı tablo",
                "Without headings": "Başlıksız tablo",
                "Delete column": "Sütunu sil",
                "Delete row": "Satırı sil",
                "Add column to left": "Sola sütun ekle",
                "Add column to right": "Sağa sütun ekle",
                "Add row above": "Yukarı satır ekle",
                "Add row below": "Aşağı satır ekle",
                Heading: "Başlık",
              },
              alert: {
                Warning: "Turuncu",
                Danger: "Kırmızı",
                Info: "Turkuaz",
                Success: "Yeşil",
                Secondary: "Gri",
                Primary: "Koyu Mavi",
                Dark: "Koyu",
                Light: "Açık",
              },
              underline: {
                Underline: "Altı çizili",
              },
            },
          },
        },
        onReady: () => this.onReady(),
        tunes: ["alignment"],
        tools: {
          header: {
            class: Header,
            inlineToolbar: ["italic", "underline", "link"],
            config: {
              levels: [1, 2, 3, 4, 5, 6],
              defaultLevel: 2,
              placeholder: "Başlık giriniz",
            },
          },
          list: {
            class: List,
            inlineToolbar: true,
            config: {
              placeholder: "Liste giriniz",
            },
          },
          table: {
            class: Table,
            inlineToolbar: true,
            config: {
              rows: 2,
              cols: 3,
            },
            tunes: [],
          },
          underline: {
            class: Underline,
            shortcut: "CMD+U",
          },
          alignment: {
            class: AlignmentTuneTool,
            config: {
              default: "left",
              blocks: {
                header: "center",
                list: "left",
              },
            },
          },
          image: {
            class: ImageTool,
            config: {
              uploader: {
                async uploadByFile(file) {
                  const res = await axios.post(
                    "https://explorer.arkadyas.com/api/files/upload",
                    { file },
                    {
                      headers: {
                        Authorization: `Bearer 418d8b196b2a08cd7a7d37179f6c4246a59401bb5323fb3eebdb96b90ec411d3`,
                        "Content-Type": "multipart/form-data",
                      },
                    }
                  );

                  if (res.status == 200) {
                    return {
                      success: 1,
                      file: {
                        url: res.data.url,
                      },
                    };
                  }

                  return { success: 0 };
                },
                uploadByUrl(url) {
                  return {
                    success: 1,
                    file: {
                      url,
                    },
                  };
                },
              },
            },
          },
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.bg-gray {
  background-color: #e7e7e7;
}
</style>
