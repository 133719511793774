<template>
  <div
    class="total-participant mb-2 text-muted d-flex fw-500 text-center justify-content-center"
  >
    <i class="material-icons-outlined me-1">people</i>
    Toplam kişi: {{ _total_participant }}
  </div>
  <div class="d-flex justify-content-center">
    <button
      @click="sendMailPopup()"
      class="btn btn-success btn-iconed mx-2 my-2 fw-500 text-small"
    >
      Mail Gönder
      <i class="material-icons-outlined ms-2">email</i>
    </button>
    <button
      @click="sendPermissionPopup()"
      v-if="_admin.role == 'super_admin'"
      class="btn btn-primary btn-iconed mx-2 my-2 fw-500 text-small"
    >
      Bildirim Onay Metni Gönder
      <i class="material-icons-outlined ms-2">notifications</i>
    </button>
    <button
      @click="sendSmsPopup()"
      class="btn btn-success btn-iconed mx-2 my-2 fw-500 text-small"
    >
      Sms Gönder
      <i class="material-icons-outlined ms-2">sms</i>
    </button>
  </div>
  <div class="d-flex justify-content-center mb-3">
    <button
      @click="createParticipantPopup()"
      class="btn btn-secondary btn-iconed mx-2 my-2 fw-500 text-small"
    >
      Kişi Oluştur
      <i class="material-icons-outlined ms-2">add</i>
    </button>
    <button
      @click="createGroup()"
      class="btn btn-secondary btn-iconed mx-2 my-2 fw-500 text-small"
      v-if="_admin.role == 'super_admin'"
    >
      Grup Oluştur
      <i class="material-icons-outlined ms-2">group_add</i>
    </button>
    <button
      @click="advancedReportPopup()"
      class="btn btn-secondary btn-iconed mx-2 my-2 fw-500 text-small"
    >
      Gelişmiş Raporlama
      <i class="material-icons-outlined ms-2">display_settings</i>
    </button>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "HomeButtonsComp",
  computed: {
    ...mapGetters(["_total_participant", "_settings", "_admin"]),
  },
  methods: {
    sendMailPopup() {
      if (this._settings.mail_credit > 0)
        this.$store.getters._send_mail_popup.show();
      else
        this.$swal({
          text: "Mail Krediniz Tükenmiştir. Lütfen Yetkili ile iletişime geçiniz.",
          icon: "warning",
        });
    },
    sendSmsPopup() {
      if (this._settings.sms_credit > 0)
        this.$store.getters._send_sms_popup.show();
      else
        this.$swal({
          text: "Sms Krediniz Tükenmiştir. Lütfen Yetkili ile iletişime geçiniz.",
          icon: "warning",
        });
    },
    createParticipantPopup() {
      this.$store.getters._create_participant_popup.show();
    },
    sendPermissionPopup() {
      this.$store.getters._send_permission_popup.show();
    },
    advancedReportPopup() {
      this.$store.getters._advanced_report_popup.show();
    },
    createGroup() {
      this.$swal({
        title: "Grup Oluştur",
        input: "text",
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: "Kaydet",
        cancelButtonText: "İptal",

        toast: false,
        timer: false,
        position: "",

        preConfirm: async (text) => {
          return this.$store
            .dispatch("CREATE_GROUP", {
              text,
            })
            .then((response) => {
              return response;
            })
            .catch((error) => {
              this.$swal.showValidationMessage(
                error.message ||
                  "Bir sorun oluştu lütfen daha sonra tekrar deneyiniz."
              );
            });
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            icon: result.value.status,
            text: result.value.message,
          });
        }
        this.$store.dispatch("GET_BRANCHES_GROUPS").catch((err) => {
          this.$swal({
            icon: err?.status,
            text: err?.message,
          });
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
