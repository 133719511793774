<template>
  <form
    class="d-flex align-items-center just-fy-content-between mb-3"
    @submit.prevent="filter"
  >
    <select
      name="filter"
      class="form-control select me-3"
      v-model="_participant_filter.branch"
      v-if="_admin.role == 'super_admin'"
    >
      <option value="">Branş Seç</option>
      <option v-for="branch of _branches" :value="branch.branch" :key="branch">
        {{ branch.branch }} - ({{ branch.count }} Kişi)
      </option>
    </select>
    <select
      name="filter"
      class="form-control select me-3"
      v-model="_participant_filter.group"
      v-if="_admin.role == 'super_admin'"
    >
      <option value="">Grup Seç</option>
      <option v-for="group of _groups" :value="group._id" :key="group">
        {{ group.text }}
      </option>
    </select>
    <input
      type="text"
      class="form-control me-3"
      name="search"
      placeholder="Kişilerde Ara"
      v-model="_participant_filter.term"
    />
    <button type="submit" class="btn btn-sm btn-success me-3">Ara</button>
    <a @click="clearFilter()" class="btn btn-sm btn-primary">
      Filtreyi&nbsp;Temizle
    </a>
  </form>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "FilterComp",
  computed: {
    ...mapGetters(["_admin", "_groups", "_branches", "_participant_filter"]),
  },
  methods: {
    filter() {
      this.$store.commit("setParticipantCurrentPage", 1);
      this.$store.dispatch("GET_PARTICIPANTS").catch((err) => {
        this.$swal({
          icon: err?.status,
          text: err?.message,
        });
      });
    },
    clearFilter() {
      this.$store.commit("setParticipantFilter", {
        term: "",
        branch: "",
        group: "",
      });
      this.$store.dispatch("GET_PARTICIPANTS").catch((err) => {
        this.$swal({
          icon: err?.status,
          text: err?.message,
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
