import axios from "axios";

export default {
  state: {
    participants: [],
    total_participant: 0,
    total_participant_page: 0,
    participant_current_page: 1,
    participant_filter: {
      term: "",
      branch: "",
      group: "",
    },
    participants_loading: false,
    edit_participant: {
      general_informations: [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      ],
    },
    branches: [],
    groups: [],
  },
  getters: {
    _participants(context) {
      return context.participants;
    },
    _total_participant(context) {
      return context.total_participant;
    },
    _total_participant_page(context) {
      return context.total_participant_page;
    },
    _edit_participant(context) {
      return context.edit_participant;
    },
    _branches(context) {
      return context.branches;
    },
    _groups(context) {
      return context.groups;
    },
    _participant_current_page(context) {
      return context.participant_current_page;
    },
    _participant_filter(context) {
      return context.participant_filter;
    },
    _participants_loading(context) {
      return context.participants_loading;
    },
  },
  mutations: {
    setParticipants(context, payload) {
      context.participants = payload;
    },
    setTotalParticipant(context, payload) {
      context.total_participant = payload;
    },
    setTotalParticipantPage(context, payload) {
      context.total_participant_page = payload;
    },
    setEditParticipant(context, payload) {
      context.edit_participant = payload;
    },
    setBranches(context, payload) {
      context.branches = payload;
    },
    setGroups(context, payload) {
      context.groups = payload;
    },
    setParticipantCurrentPage(context, payload) {
      context.participant_current_page = payload;
    },
    setParticipantFilter(context, payload) {
      context.participant_filter = payload;
    },
    setParticipantLoading(context, payload) {
      context.participants_loading = payload;
    },
  },
  actions: {
    GET_PARTICIPANTS(context) {
      return new Promise((resolve, reject) => {
        context.commit("setParticipantLoading", true);
        setTimeout(() => {
          axios
            .get(`/participants/get`, {
              headers: {
                Authorization: `Bearer ${context.getters._token}`,
              },
              params: {
                page: context.getters._participant_current_page,
                ...context.getters._participant_filter,
              },
            })
            .then((res) => {
              context.commit("setParticipants", res.data.participants);
              context.commit("setTotalParticipant", res.data.total_participant);
              context.commit(
                "setTotalParticipantPage",
                Math.ceil(res.data.filter_total_participant / 20)
              );
              resolve(res.data);
            })
            .catch((err) => {
              context.commit("setParticipants", []);
              context.commit("setTotalParticipant", 0);
              context.commit("setTotalParticipantPage", 0);
              reject(err?.response?.data || err);
            })
            .finally(() => context.commit("setParticipantLoading", false));
        }, 500);
      });
    },
    GET_PARTICIPANT(context, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/participants/get/${id}`, {
            headers: {
              Authorization: `Bearer ${context.getters._token}`,
            },
          })
          .then((res) => {
            context.commit("setEditParticipant", res.data.participant);
            resolve(res.data);
          })
          .catch((err) => {
            reject(err?.response?.data || err);
          });
      });
    },
    GET_BRANCHES_GROUPS(context) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/participants/branches_groups`, {
            headers: {
              Authorization: `Bearer ${context.getters._token}`,
            },
          })
          .then((res) => {
            context.commit("setBranches", res.data.branches);
            context.commit("setGroups", res.data.groups);
            resolve(res.data);
          })
          .catch((err) => {
            reject(err?.response?.data || err);
          });
      });
    },
    CREATE_PARTICIPANT(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/participants/create`, payload, {
            headers: {
              Authorization: `Bearer ${context.getters._token}`,
            },
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err?.response?.data || err);
          });
      });
    },
    DELETE_PARTICIPANT(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/participants/delete/${payload.id}`, {
            headers: {
              Authorization: `Bearer ${context.getters._token}`,
            },
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err?.response?.data || err);
          });
      });
    },
    UPDATE_PARTICIPANT(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/participants/update/${payload._id}`, payload, {
            headers: {
              Authorization: `Bearer ${context.getters._token}`,
            },
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err?.response?.data || err);
          });
      });
    },
    UPLOAD_EXCEL(context, payload) {
      return new Promise((resolve, reject) => {
        context.commit("setLocalTasks", [
          {
            _id: "1",
            type: "file_upload",
            status: "pending",
            text: "Kişileriniz yükleniyor.",
            data: 100,
            completed: 0,
          },
        ]);
        context.commit("setTasksOpen", true);
        axios
          .post(`/participants/upload_excel`, payload, {
            headers: {
              Authorization: `Bearer ${context.getters._token}`,
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              let percent = Math.floor((loaded * 100) / total);
              if (percent <= 100) {
                context.commit("setLocalTaskIncrement", {
                  id: "1",
                  percent,
                });
              }
            },
          })
          .then((res) => {
            resolve(res.data);
            context.commit("setLocalTaskStatus", {
              id: "1",
              status: "success",
            });
            context.commit("setLocalTaskText", {
              id: "1",
              text: "Kişiler Yüklendi",
            });
          })
          .catch((err) => {
            reject(err?.response?.data || err);
            context.commit("setLocalTaskStatus", {
              id: "1",
              status: "error",
            });
            context.commit("setLocalTaskText", {
              id: "1",
              text: "Kişiler Yüklenemedi",
            });
          });
      });
    },
    SEND_MAIL(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/participants/mail`, payload, {
            headers: {
              Authorization: `Bearer ${context.getters._token}`,
            },
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err?.response?.data || err);
          });
      });
    },
    SEND_SMS(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/participants/sms`, payload, {
            headers: {
              Authorization: `Bearer ${context.getters._token}`,
            },
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err?.response?.data || err);
          });
      });
    },
    SEND_PERMISSION(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/participants/send_permission`, payload, {
            headers: {
              Authorization: `Bearer ${context.getters._token}`,
            },
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err?.response?.data || err);
          });
      });
    },
    CREATE_GROUP(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/groups/create`, payload, {
            headers: {
              Authorization: `Bearer ${context.getters._token}`,
            },
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err?.response?.data || err);
          });
      });
    },
    DELETE_GROUP(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/groups/delete/${payload.id}`, {
            headers: {
              Authorization: `Bearer ${context.getters._token}`,
            },
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err?.response?.data || err);
          });
      });
    },
  },
};
