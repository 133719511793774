<template>
  <div class="modal fade" tabindex="-1" id="createParticipantModal">
    <div class="modal-dialog modal-xl modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Kişi Oluştur</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body" :class="{ blur: loading }">
          <form>
            <div class="tab-content">
              <div class="tab-pane fade" id="create_tab_1" role="tabpanel">
                <h3>Kişisel Bilgiler</h3>
                <div class="d-flex">
                  <fieldset class="field me-3">
                    <label class="form-label">
                      Ad Soyad
                      <span class="text-danger ms-1">*</span>
                    </label>
                    <input
                      class="form-control"
                      type="text"
                      v-model="full_name"
                    />
                  </fieldset>

                  <fieldset class="field me-3">
                    <label class="form-label">
                      Cinsiyet
                      <span class="text-danger ms-1">*</span>
                    </label>
                    <select class="form-control" v-model="gender">
                      <option value="">Seçiniz</option>
                      <option value="Erkek">Erkek</option>
                      <option value="Kadın">Kadın</option>
                    </select>
                    <i class="material-icons-outlined dropdown-arrow"
                      >keyboard_arrow_down</i
                    >
                  </fieldset>

                  <fieldset class="field me-3">
                    <label class="form-label"> Grup </label>
                    <select class="form-control" v-model="group">
                      <option v-bind:value="null">Seçiniz</option>
                      <option
                        v-for="group of _groups"
                        :value="group._id"
                        :key="group"
                      >
                        {{ group.text }}
                      </option>
                    </select>
                    <i class="material-icons-outlined dropdown-arrow"
                      >keyboard_arrow_down</i
                    >
                  </fieldset>
                  <fieldset class="field me-3">
                    <label class="form-label"> Branş </label>
                    <input class="form-control" type="text" v-model="branch" />
                  </fieldset>
                  <fieldset class="field me-3">
                    <label class="form-label"> Telefon </label>
                    <input
                      class="form-control phone-input"
                      type="text"
                      placeholder="+90 5XX XXX XXXX"
                      v-model="telephone"
                    />
                  </fieldset>
                  <fieldset class="field">
                    <label class="form-label">2. Telefon</label>
                    <input
                      class="form-control phone-input"
                      type="text"
                      placeholder="+90 5XX XXX XXXX"
                      v-model="telephone_2"
                    />
                  </fieldset>
                </div>
                <div class="d-flex">
                  <fieldset class="field me-3">
                    <label class="form-label"> E Posta </label>
                    <input
                      class="form-control"
                      type="email"
                      v-model="mail"
                      placeholder="ornek@arkadyas.com"
                    />
                  </fieldset>
                  <fieldset class="field me-3">
                    <label class="form-label"> 2. E Posta </label>
                    <input
                      class="form-control"
                      type="email"
                      v-model="mail_2"
                      placeholder="ornek@arkadyas.com"
                    />
                  </fieldset>
                  <fieldset class="field me-3">
                    <label class="form-label"> Kimlik Numarası </label>
                    <input
                      class="form-control"
                      type="text"
                      v-model="identification_number"
                      placeholder="00000000000"
                    />
                  </fieldset>
                  <fieldset class="field me-3">
                    <label class="form-label"> Pasaport Numarası </label>
                    <input
                      class="form-control"
                      type="text"
                      v-model="passport_number"
                      placeholder="000000000"
                    />
                  </fieldset>
                  <fieldset class="field">
                    <label class="form-label"> Doğum Tarihi </label>
                    <input
                      class="form-control"
                      type="date"
                      v-model="date_of_birth"
                    />
                  </fieldset>
                </div>
                <div class="d-flex">
                  <fieldset class="field me-3">
                    <label class="form-label"> Çalıştığı Kurum </label>
                    <input class="form-control" type="text" v-model="company" />
                  </fieldset>
                  <fieldset class="field me-3">
                    <label class="form-label"> Bölüm </label>
                    <input class="form-control" type="text" v-model="section" />
                  </fieldset>
                  <fieldset class="field me-3">
                    <label class="form-label"> Uzmanlık </label>
                    <input
                      class="form-control"
                      type="text"
                      v-model="expertise"
                    />
                  </fieldset>
                  <fieldset class="field">
                    <label class="form-label"> Ünvan </label>
                    <input class="form-control" type="text" v-model="title" />
                  </fieldset>
                </div>
                <div class="d-flex">
                  <fieldset class="field">
                    <label class="form-label"> Ülke </label>
                    <select class="form-control" v-model="country">
                      <option v-bind:value="null">Seçiniz</option>
                      <option
                        v-for="country of Countries"
                        :value="country.name"
                        :key="country"
                      >
                        {{ country.name }}
                      </option>
                    </select>
                  </fieldset>
                  <fieldset class="field me-3">
                    <label class="form-label"> Şehir </label>
                    <select class="form-control" v-model="city">
                      <option v-bind:value="null">Seçiniz</option>
                      <option
                        v-for="city of cities"
                        :disabled="country == null"
                        :value="city"
                        :key="city"
                      >
                        {{ city }}
                      </option>
                    </select>
                  </fieldset>
                </div>
                <div class="d-flex">
                  <fieldset class="field me-3">
                    <label class="form-label"> İş Adresi </label>
                    <textarea class="form-control" v-model="address"></textarea>
                  </fieldset>
                  <fieldset class="field">
                    <label class="form-label"> Ev Adresi </label>
                    <textarea
                      class="form-control"
                      v-model="home_address"
                    ></textarea>
                  </fieldset>
                </div>
                <div class="d-flex">
                  <fieldset class="flex-fill field me-0">
                    <label class="form-label"> Not </label>
                    <textarea class="form-control" v-model="note"></textarea>
                  </fieldset>
                </div>
              </div>
              <div class="tab-pane fade" id="create_tab_2" role="tabpanel">
                <h3>Genel Bilgiler</h3>
                <div class="d-flex">
                  <fieldset class="field me-3">
                    <label class="form-label">Genel Bilgi 1</label>
                    <textarea
                      data-name="question_1"
                      rows="2"
                      class="form-control"
                      v-model="general_informations[0]"
                    ></textarea>
                  </fieldset>
                  <fieldset class="field me-3">
                    <label class="form-label">Genel Bilgi 2</label>
                    <textarea
                      data-name="question_2"
                      rows="2"
                      class="form-control"
                      v-model="general_informations[1]"
                    ></textarea>
                  </fieldset>
                  <fieldset class="field me-3">
                    <label class="form-label">Genel Bilgi 3</label>
                    <textarea
                      data-name="question_3"
                      rows="2"
                      class="form-control"
                      v-model="general_informations[2]"
                    ></textarea>
                  </fieldset>
                  <fieldset class="field">
                    <label class="form-label">Genel Bilgi 4</label>
                    <textarea
                      data-name="question_4"
                      rows="2"
                      class="form-control"
                      v-model="general_informations[3]"
                    ></textarea>
                  </fieldset>
                </div>
                <div class="d-flex mb-1">
                  <fieldset class="field me-3">
                    <label class="form-label">Genel Bilgi 5</label>
                    <textarea
                      data-name="question_5"
                      rows="2"
                      class="form-control"
                      v-model="general_informations[4]"
                    ></textarea>
                  </fieldset>
                  <fieldset class="field me-3">
                    <label class="form-label">Genel Bilgi 6</label>
                    <textarea
                      data-name="question_6"
                      rows="2"
                      class="form-control"
                      v-model="general_informations[5]"
                    ></textarea>
                  </fieldset>
                  <fieldset class="field me-3">
                    <label class="form-label">Genel Bilgi 7</label>
                    <textarea
                      data-name="question_7"
                      rows="2"
                      class="form-control"
                      v-model="general_informations[6]"
                    ></textarea>
                  </fieldset>
                  <fieldset class="field">
                    <label class="form-label">Genel Bilgi 8</label>
                    <textarea
                      data-name="question_8"
                      rows="2"
                      class="form-control"
                      v-model="general_informations[7]"
                    ></textarea>
                  </fieldset>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <div class="d-flex w-100 justify-content-between">
            <ul class="left d-flex nav nav-tabs border-0" role="tablist">
              <li class="nav-item active" role="presentation">
                <button
                  data-bs-toggle="tab"
                  data-bs-target="#create_tab_1"
                  type="button"
                  role="tab"
                  aria-controls="create_tab_1"
                  aria-selected="true"
                  class="btn btn-secondary btn-sm btn-iconed m-2"
                >
                  Kişisel Bilgiler
                  <i class="material-icons-outlined">badge</i>
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  data-bs-toggle="tab"
                  data-bs-target="#create_tab_2"
                  type="button"
                  role="tab"
                  aria-controls="create_tab_2"
                  aria-selected="false"
                  class="btn btn-secondary btn-sm btn-iconed m-2"
                >
                  Genel Bilgiler
                  <i class="material-icons-outlined">more_horiz</i>
                </button>
              </li>
            </ul>
            <div class="right d-flex">
              <button
                type="submit"
                class="btn btn-success small btn-sm btn-iconed update-btn m-2"
                :disabled="loading"
                @click="createParticipant()"
              >
                {{ loading ? "Kaydediliyor.." : "Kaydet" }}
                <i class="material-icons-outlined">save</i>
              </button>
              <button
                type="button"
                class="btn btn-sm btn-danger small btn-iconed m-2"
                data-bs-dismiss="modal"
              >
                İptal
                <i class="material-icons-outlined">close</i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min.js";
import { mapGetters } from "vuex";

import Countries from "@/utils/Countries";

export default {
  name: "CreateParticipantPopup",
  computed: {
    ...mapGetters(["_groups"]),
  },
  data() {
    return {
      loading: false,
      full_name: null,
      gender: "",
      group: null,
      branch: null,
      telephone: null,
      telephone_2: null,
      mail: null,
      mail_2: null,
      identification_number: null,
      passport_number: null,
      date_of_birth: null,
      company: null,
      section: null,
      expertise: null,
      title: null,
      note: null,
      city: null,
      country: null,
      address: null,
      home_address: null,
      general_informations: [null, null, null, null, null, null, null, null],
      Countries,
      cities: [],
    };
  },
  mounted() {
    this.$store.commit(
      "setCreateParticipantPopup",
      new bootstrap.Modal("#createParticipantModal")
    );
    document.querySelector("[aria-controls='create_tab_1']").click();
  },
  watch: {
    country(val) {
      for (const country of this.Countries) {
        if (country.name == val) {
          this.cities = country.cities;
          break;
        }
        this.cities = [];
      }
    },
  },
  methods: {
    clearState() {
      this.full_name = null;
      this.gender = "";
      this.group = null;
      this.branch = null;
      this.telephone = null;
      this.telephone_2 = null;
      this.mail = null;
      this.mail_2 = null;
      this.identification_number = null;
      this.passport_number = null;
      this.date_of_birth = null;
      this.company = null;
      this.section = null;
      this.expertise = null;
      this.title = null;
      this.note = null;
      this.city = null;
      this.country = null;
      this.address = null;
      this.home_address = null;
      this.general_informations = [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      ];
    },
    createParticipant() {
      this.loading = true;
      setTimeout(() => {
        this.$store
          .dispatch("CREATE_PARTICIPANT", {
            full_name: this.full_name,
            gender: this.gender,
            group: this.group,
            branch: this.branch,
            telephone: this.telephone,
            telephone_2: this.telephone_2,
            mail: this.mail,
            mail_2: this.mail_2,
            identification_number: this.identification_number,
            passport_number: this.passport_number,
            date_of_birth: this.date_of_birth,
            company: this.company,
            section: this.section,
            expertise: this.expertise,
            title: this.title,
            note: this.note,
            city: this.city,
            country: this.country,
            address: this.address,
            home_address: this.home_address,
            general_informations: this.general_informations,
          })
          .then((res) => {
            this.$store.dispatch("GET_PARTICIPANTS").catch((err) => {
              this.$swal({
                icon: err?.status,
                text: err?.message,
              });
            });
            this.$store.dispatch("GET_BRANCHES_GROUPS").catch((err) => {
              this.$swal({
                icon: err?.status,
                text: err?.message,
              });
            });
            this.$swal({
              icon: "success",
              text: res.message,
            });
            this.$store.getters._create_participant_popup.hide();
            this.clearState();
          })
          .catch((err) => {
            this.$swal({
              icon: "error",
              text: err?.message || err,
            });
          })
          .finally(() => (this.loading = false));
      }, 1000);
    },
  },
};
</script>
<style lang="scss"></style>
