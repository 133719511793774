export default {
  state: {
    send_mail_popup: null,
    participant_send_mail_popup: null,
    send_sms_popup: null,
    participant_send_sms_popup: null,
    edit_participant_popup: null,
    create_participant_popup: null,
    send_permission_popup: null,
    advanced_report_popup: null,
  },
  getters: {
    _send_mail_popup(context) {
      return context.send_mail_popup;
    },
    _participant_send_mail_popup(context) {
      return context.participant_send_mail_popup;
    },
    _send_sms_popup(context) {
      return context.send_sms_popup;
    },
    _participant_send_sms_popup(context) {
      return context.participant_send_sms_popup;
    },
    _edit_participant_popup(context) {
      return context.edit_participant_popup;
    },
    _create_participant_popup(context) {
      return context.create_participant_popup;
    },
    _send_permission_popup(context) {
      return context.send_permission_popup;
    },
    _advanced_report_popup(context) {
      return context.advanced_report_popup;
    },
  },
  mutations: {
    setSendMailPopup(context, payload) {
      context.send_mail_popup = payload;
    },
    setParticipantSendMailPopup(context, payload) {
      context.participant_send_mail_popup = payload;
    },
    setSendSmsPopup(context, payload) {
      context.send_sms_popup = payload;
    },
    setParticipantSendSmsPopup(context, payload) {
      context.participant_send_sms_popup = payload;
    },
    setEditParticipantPopup(context, payload) {
      context.edit_participant_popup = payload;
    },
    setCreateParticipantPopup(context, payload) {
      context.create_participant_popup = payload;
    },
    setSendPermissionPopup(context, payload) {
      context.send_permission_popup = payload;
    },
    setAdvancedReportPopup(context, payload) {
      context.advanced_report_popup = payload;
    },
  },
  actions: {},
};
