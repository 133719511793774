import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "@/views/HomeView";
import PageNotFound from "@/views/PageNotFound";

const routes = [
  {
    path: "/",
    name: "HomeView",
    component: HomeView,
    meta: {
      auth: true,
    },
  },
  {
    path: "/mergeds",
    name: "MergedsView",
    component: () => import("@/views/MergedsView"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/settings",
    name: "SettingsView",
    component: () => import("@/views/SettingsView"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/admins",
    name: "AdminsView",
    component: () => import("@/views/AdminsView"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/admin/:id",
    name: "AdminEditView",
    component: () => import("@/views/AdminEditView"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/login",
    name: "LoginView",
    component: () => import("@/views/LoginView"),
  },
  {
    path: "/sms_permission/:id",
    name: "SmsPermissionView",
    component: () => import("@/views/SmsPermissionView"),
  },
  {
    path: "/mail_permission/:id",
    name: "MailPermissionView",
    component: () => import("@/views/MailPermissionView"),
  },
  { path: "/:pathMatch(.*)*", name: "NotFound", component: PageNotFound },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
