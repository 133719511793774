import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

/* AXİOS */
import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_BASE || "/api";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      (error?.response?.status === 401 || error?.response?.status === 403) &&
      error?.response.data.type == "auth"
    ) {
      store.dispatch("LOGOUT");
      router.push({ name: "LoginView" });
    }
    return Promise.reject(error);
  }
);

/* SWEETALERT 2 */
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

/* BOOTSTRAP */
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

/* BOOTSTRAP ICONS */
import "bootstrap-icons/font/bootstrap-icons.css";

import "@/assets/css/style.css";

const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueSweetalert2, {
  toast: true,
  timer: 5 * 1000,
  position: "top-end",
  showConfirmButton: false,
  timerProgressBar: true,
});
app.mount("#app");
