<template>
  <div class="row justify-content-center mt-3">
    <div class="col-8">
      <form
        class="d-flex flex-column align-items-center"
        @submit.prevent="uploadExcel()">
        <input
          type="file"
          class="upload-excel d-none"
          @change="onFileChange"
          id="uploadExcel"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
        <label
          for="uploadExcel"
          class="form-file-label"
          :class="{ active: excel_file }"
          id="formFileLabel"></label>
        <transition name="blur" mode="out-in">
          <div class="buttons d-flex" v-if="excel_file">
            <button
              type="reset"
              @click="clearFile"
              class="btn btn-sm btn-danger btn-iconed shadow me-2">
              Vazgeç
              <span class="material-icons-outlined">clear</span>
            </button>
            <button
              type="submit"
              class="btn btn-sm btn-secondary btn-iconed shadow ms-2">
              Yükle
              <span class="material-icons-outlined">file_upload</span>
            </button>
          </div>
        </transition>
      </form>
      <div class="my-3 d-flex justify-content-center">
        <a
          href="https://api-indeks.lcv-davet.com/ArkadyasOrnekExcel.xlsx"
          class="btn btn-iconed btn-sm text-sm btn-primary"
          download="">
          Örnek Excel İndir
          <i class="material-icons-outlined">download</i>
        </a>
      </div>
      <div class="alert alert-warning d-flex align-items-center mb-0">
        <i class="material-icons-outlined me-3">warning</i>
        <span class="fw-500 text-small">
          Lütfen excel dosyası yüklemeden önce aşağıdaki kurallara göz atın,
          kurallara uygun olmayan dosyalar kayıt edilmeyecektir.
        </span>
      </div>
      <strong class="d-block my-3 fw-500 fs-5 text-dark">
        Excel yükleme kuralları
      </strong>
      <ul>
        <li>
          Dosya uzantısı sadece <strong>.xls</strong> veya
          <strong>.xlsx</strong> olmalıdır.
        </li>
        <li>Dosya boyutu en fazla <strong>100MB</strong> olabilir.</li>
        <li>Tablodaki veri düzeni sırasıyla şu şekilde olmalıdır;</li>
        <ul>
          <li>
            Ad Soyad
            <span class="text-danger fw-500">*</span>
          </li>
          <li>Cinsiyet <span class="text-danger fw-500">Erkek/Kadın</span></li>
          <li>Branş</li>
          <li>Telefon</li>
          <li>Telefon 2</li>
          <li>Mail</li>
          <li>Mail 2</li>
          <li>Kimlik Numarası</li>
          <li>Doğum Tarihi</li>
          <li>Çalıştığı Kurum</li>
          <li>Bölüm</li>
          <li>Uzmanlık</li>
          <li>Ünvan</li>
          <li>Not</li>
          <li>Şehir</li>
          <li>Ülke</li>
          <li>İş Adresi</li>
          <li>Ev Adresi</li>
          <li>Genel Bilgi 1</li>
          <li>Genel Bilgi 2</li>
          <li>Genel Bilgi 3</li>
          <li>Genel Bilgi 4</li>
          <li>Genel Bilgi 5</li>
          <li>Genel Bilgi 6</li>
          <li>Genel Bilgi 7</li>
          <li>Genel Bilgi 8</li>
          <li>Grup</li>
        </ul>
        <li>
          Zorunlu alanlar <span class="text-danger fw-500">*</span> ile
          gösterilmiştir.
        </li>
        <li>
          Excel dosyanız boyutuna bağlı olarak yüklemesi biraz zaman alabilir,
          lütfen bekleyin. İşlem tamamlandığında olumlu veya olumsuz bir dönüş
          alırsınız.
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "ExcelUploadComp",
  data() {
    return {
      excel_file: null,
      buttons: false,
    };
  },

  methods: {
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createFile(files[0]);
    },
    createFile(file) {
      document
        .querySelector("#formFileLabel")
        .setAttribute("data-file", file.name);
      this.excel_file = file;
    },
    clearFile() {
      this.excel_file = "";
      document.querySelector("#formFileLabel").removeAttribute("data-file");
      document.querySelector("#uploadExcel").value = null;
    },
    uploadExcel() {
      let formData = new FormData();
      formData.append("excel_file", this.excel_file);
      this.$store
        .dispatch("UPLOAD_EXCEL", formData)
        .then((res) => {
          this.clearFile();
          this.$swal({
            icon: res?.status,
            text: res?.message,
          });
          this.$store.dispatch("GET_PARTICIPANTS").catch((err) => {
            this.$swal({
              icon: err?.status,
              text: err?.message,
            });
          });
          this.$store.dispatch("GET_BRANCHES_GROUPS").catch((err) => {
            this.$swal({
              icon: err?.status,
              text: err?.message,
            });
          });
        })
        .catch((err) => {
          this.$swal({
            icon: err?.status,
            text: err?.message,
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.form-file-label {
  padding: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  font-size: 1.35rem;
  border-radius: 0.5rem;
  font-weight: 600;
  width: 100%;
  margin-bottom: 1.5rem;
  color: rgba(var(--bs-dark-rgb), 0.8);
  border: 2px dashed rgb(var(--bs-secondary-rgb));
  background: rgb(var(--bs-white-rgb));
  transition: all 300ms ease-in-out;
  &::before {
    content: "Kişileri yüklemek için bir excel dosyası seçiniz";
  }

  &.active {
    color: rgba(var(--bs-success-rgb), 0.8);
    border: 2px dashed rgb(var(--bs-success-rgb));
    background: rgba(var(--bs-success-rgb), 0.1);
    &::before {
      content: "Dosya seçiminiz: " attr(data-file);
    }
  }
}
</style>
